<template>
  <b-card>
    <b-form>
      <b-row>
        <!-- Profile Connections -->
        <b-col
          cols="12"
          class="mt-1"
        >
          <div class="d-flex align-items-center mb-3">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Интеграция с соц. сетями
            </h4>
          </div>
          <b-row class="text-center">
            <!-- twitter profile -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              <b-card-text class="font-weight-bold">
                Telegram
              </b-card-text>
              <b-card-text class="mb-0">
                @test_telegram
              </b-card-text>
              <b-link>
                Отключить
              </b-link>
            </b-col>
            <!--/ twitter profile -->

            <!-- facebook connect button -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              <b-card-text class="font-weight-bold mb-2">
                Telegram
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                Подключить
              </b-button>
            </b-col>
            <!--/ facebook connect button -->
          </b-row>
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Сохранить
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Отменить
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
  },
}
</script>
